import { ReactNode } from 'react';
import { ListItemContainer, ListItemContent, ListItemBullet } from './styles';

interface ListItemProps {
    listType: string;
    index: number;
    children: ReactNode;
}

export default function ({ listType, index, children }: ListItemProps) {
    const intToRoman = (index: number) => {
        const values = [50, 40, 10, 9, 5, 4, 1];
        const numerals = ['L', 'XL', 'X', 'IX', 'V', 'IV', 'I'];

        let result = '';
        for (let i = 0; i < values.length; i++) {
            while (index >= values[i]) {
                result += numerals[i];
                index -= values[i];
            }
        }

        return result;
    };

    const getBullet = () => {
        switch (listType) {
            case '1':
                return index + 1 + '.';
            case 'I':
                return `${intToRoman(index)}.`;
            case 'i':
                return `${intToRoman(index).toLowerCase()}.`;
            case 'A':
                return `${String.fromCharCode(65 + index)}.`;
            case 'a':
                return `${String.fromCharCode(97 + index)}.`;
            default:
                return '\u2022';
        }
    };

    return (
        <ListItemContainer>
            <ListItemBullet>{getBullet()}</ListItemBullet>
            <ListItemContent>{children}</ListItemContent>
        </ListItemContainer>
    );
}
