import BBCode from '@bbob/react/lib';
import presetReact from '@bbob/preset-react/lib';
import Quote from './Quote';
import Expand from './Expand';
import Video from './Video';
import Table from './Table';
import Latex from './Latex';
import List from './List';
import Field from './Field';
import Size from './Size';
import Image from './Image';
import { runtimeConfig } from '../../runtimeConfig';
import { supportedTags } from './supportedTags';
import convertSmiliesToBBCode from '../../helpers/Smilies/convertSmiliesToBBCode';
import { Attachment } from 'types/thread';
import { Attach } from './Attach';
import { InlineLink } from '../Link/styles';

interface BBCodeParserProps {
    content: string;
    attachments?: Attachment[];
    isFirstPost: boolean;
    isThreadOpen?: boolean;
    includeStructuredData?: boolean;
}

function parseColourBBCode(node: any) {
    const colour = node.attrs[Object.keys(node.attrs)[0]];
    return {
        tag: 'font',
        attrs: { color: colour },
        content: node.content
    };
}

function parseSmilieBBCode(nodeContent: string, isFirstPost: boolean, includeStructuredData?: boolean) {
    const smilie = nodeContent[0];
    return {
        tag: Image,
        attrs: {
            src: `${runtimeConfig.staticDomain}/images/smilies/${smilie}.gif`,
            alt: `:${smilie}:`,
            title: `:${smilie}:`,
            loading: `${isFirstPost ? 'eager' : 'lazy'}`,
            includeStructuredData: includeStructuredData
        }
    };
}

function parseLatexBBCode(node: any) {
    // If content is empty, don't render
    if (!node.content.length) return;

    return {
        tag: Latex,
        content: node.content.join('')
    };
}

export default function BBCodeParser({ content, attachments, isFirstPost, isThreadOpen, includeStructuredData }: BBCodeParserProps) {
    const preset = presetReact.extend((tags: any, options: any) => ({
        ...tags,
        left: (node: any) => {
            return {
                tag: 'div',
                attrs: { style: { textAlign: 'left' } },
                content: node.content
            };
        },
        right: (node: any) => {
            return {
                tag: 'div',
                attrs: { style: { textAlign: 'right' } },
                content: node.content
            };
        },
        center: (node: any) => {
            return {
                tag: 'div',
                attrs: { style: { textAlign: 'center' } },
                content: node.content
            };
        },
        color: parseColourBBCode,
        colour: parseColourBBCode,
        size: (node: any) => {
            const size = node.attrs[Object.keys(node.attrs)[0]];

            return {
                tag: Size,
                attrs: { size: size },
                content: node.content
            };
        },
        user: (node: any) => {
            // If content is empty, don't render
            if (!node.content.length) return;

            const userId = node.attrs[Object.keys(node.attrs)[0]];
            return {
                tag: InlineLink,
                attrs: { url: `/member.php?u=${userId}` },
                content: `@${node.content.join(' ')}`
            };
        },
        url: (node: any) => {
            // If content is empty, don't render url
            if (!node.content.length) return;

            const url = node.attrs[Object.keys(node.attrs)[0]] ?? node.content[0];
            return {
                tag: InlineLink,
                attrs: { url: url, target: '_blank' },
                content: node.content
            };
        },
        link: (node: any) => {
            // If content is empty, don't render link
            if (!node.content.length) return;

            const link = node.attrs[Object.keys(node.attrs)[0]] ?? node.content[0];
            return {
                tag: InlineLink,
                attrs: { url: link },
                content: node.content
            };
        },
        strike: (node: any) => {
            return {
                tag: 's',
                content: node.content
            };
        },
        hr: (node: any) => {
            const height = node.attrs[Object.keys(node.attrs)[0]];
            return {
                tag: 'hr',
                attrs: { style: { height: `${height}px`, backgroundColor: '#aaaaaa' } }
            };
        },
        lb: () => {
            return {
                tag: 'br'
            };
        },
        div: (node: any) => {
            const float = node.attrs[Object.keys(node.attrs)[0]];
            return {
                tag: 'div',
                attrs: { style: { float: float } },
                content: node.content
            };
        },
        div2: (node: any) => {
            return {
                tag: 'div',
                content: node.content
            };
        },
        quote: (node: any) => {
            // Filter out any empty attributes, e.g. if a quote has spaces before the username
            node.attrs = Object.fromEntries(Object.entries(node.attrs).filter(([k, v]) => v != null && (k !== "" && v !== "")));

            // Attributes are obtained by splitting spaces. This causes problems when a username contains spaces.
            // For example, [quote=user name;123] will assume the attributes ['user', 'name;123']
            // So instead, join the attributes to get the string 'user name;123', then split by ';'.
            const attributes =
                node.attrs[Object.keys(node.attrs)[0]] &&
                Object.values(node.attrs).join(' ').split(';');
            return {
                tag: Quote,
                attrs: attributes && { user: attributes[0], postId: attributes[1], isThreadOpen: isThreadOpen ?? true },
                content: node.content
            };
        },
        table: (node: any) => {
            return {
                tag: Table,
                content: node.content.filter((item: any) => item.tag == 'tr') // table tags can only contain tr tags
            };
        },
        tr: (node: any) => {
            return {
                tag: 'tr',
                content: node.content.filter((item: any) => ['td', 'th'].includes(item.tag)) // tr tags can only contain td and th tags
            };
        },
        th: (node: any) => {
            return {
                tag: 'th',
                attrs: {
                    style: {
                        border: '1px solid #c5c5c5',
                        borderBottom: '3px solid #c5c5c5',
                        padding: '5px 10px'
                    }
                },
                content: node.content
            };
        },
        td: (node: any) => {
            return {
                tag: 'td',
                attrs: { style: { border: '1px solid #c5c5c5', padding: '5px 10px' } },
                content: node.content
            };
        },
        thread: (node: any) => {
            const threadNumber = node.attrs[Object.keys(node.attrs)[0]] ?? node.content[0];
            const threadUrl = `/showthread.php?t=${threadNumber}`;
            return {
                tag: InlineLink,
                attrs: { url: threadUrl },
                // if thread number is an attribute (i.e. [thread=123]), display content. Otherwise, display url.
                content: node.attrs[Object.keys(node.attrs)[0]]
                    ? node.content
                    : threadUrl
            };
        },
        forum: (node: any) => {
            const forumNumber = node.attrs[Object.keys(node.attrs)[0]];
            const forumUrl = `/forumdisplay.php?f=${forumNumber}`;
            return {
                tag: InlineLink,
                attrs: { url: forumUrl },
                content: node.content
            };
        },
        expand: (node: any) => {
            // If content is empty, don't render expand
            if (!node.content.length) return;

            const title = Object.values(node.attrs).join(' ');

            // If the first item in an expand is a blank line then remove it
            const firstItem = node.content[0];
            if (firstItem && firstItem.hasOwnProperty('tag') && firstItem['tag'] == 'br') {
                node.content.shift();
            }

            // If the last item in an expand is a blank line then remove it
            const lastItem = node.content[node.content.length - 1];
            if (lastItem && lastItem.hasOwnProperty('tag') && lastItem['tag'] == 'br') {
                node.content.pop();
            }

            return {
                tag: Expand,
                attrs: { title: title, editable: false },
                content: node.content
            };
        },
        spoiler: (node: any) => {
            // If content is empty, don't render spoiler
            if (!node.content.length) return;

            // If the first item in a spoiler is a blank line then remove it
            const firstItem = node.content[0];
            if (firstItem && firstItem.hasOwnProperty('tag') && firstItem['tag'] == 'br') {
                node.content.shift();
            }

            // If the last item in a spoiler is a blank line then remove it
            const lastItem = node.content[node.content.length - 1];
            if (lastItem && lastItem.hasOwnProperty('tag') && lastItem['tag'] == 'br') {
                node.content.pop();
            }

            return {
                tag: Expand,
                attrs: { title: 'Spoiler', editable: false },
                content: node.content
            };
        },
        smilie: (node: any) => {
            return parseSmilieBBCode(node.content, isFirstPost, includeStructuredData);
        },
        s: (node: any) => {
            return parseSmilieBBCode(node.content, isFirstPost, includeStructuredData);
        },
        vimeo: (node: any) => {
            return {
                tag: Video,
                attrs: { src: `https://player.vimeo.com/video/${node.content}` }
            };
        },
        youtube: (node: any) => {
            return {
                tag: Video,
                attrs: { src: `https://www.youtube.com/embed/${node.content}` }
            };
        },
        attach: (node: any) => {
            return {
                tag: Attach,
                attrs: {
                    attachmentId: parseInt(node.content),
                    attachments: attachments || [],
                    isFirstPost: isFirstPost,
                    includeStructuredData: includeStructuredData
                }
            };
        },
        img: (node: any) => {
            // If [img] content empty, don't render image
            if (!node.content.length) return;

            return {
                tag: Image,
                attrs: {
                    loading: isFirstPost ? 'eager' : 'lazy',
                    src: node.content[0],
                    includeStructuredData: includeStructuredData
                }
            };
        },
        email: (node: any) => {
            // If content is empty, don't render
            if (!node.content.length) return;

            const email = node.attrs[Object.keys(node.attrs)[0]] ?? node.content[0];
            return {
                tag: InlineLink,
                attrs: { url: `mailto:${email}` },
                content: node.content
            };
        },
        tex: (node: any) => {
            return parseLatexBBCode(node);
        },
        latex: (node: any) => {
            return parseLatexBBCode(node);
        },
        list: (node: any) => {
            const listType = Object.values(node.attrs).join(' ');

            // If content is empty, don't render
            if (!node.content.length) return;

            return {
                tag: List,
                attrs: { listType: listType, BBCodeParser: BBCodeParser },
                content: node.content.join('').replace(/\[br\]/gi, '\n')
            };
        },
        code: (node: any) => {
            return {
                tag: 'code',
                content: node.content
            };
        },
        php: (node: any) => {
            return {
                tag: 'code',
                content: node.content
            };
        },
        html: (node: any) => {
            return {
                tag: 'code',
                content: node.content
            };
        },
        field: (node: any) => {
            // If content is empty, don't render
            if (!node.content.length) return;

            const fieldTitle = node.attrs[Object.keys(node.attrs)[0]];

            return {
                tag: Field,
                content: node.content.join(' '),
                attrs: {
                    title: fieldTitle,
                    BBCodeParser: BBCodeParser
                }
            };
        },
        noparse: (node: any) => {
            return {
                tag: 'font',
                content: node.content
            };
        },
    }));

    return content ? (
        <BBCode plugins={[preset()]} options={{ onlyAllowTags: supportedTags, contextFreeTags: ['noparse'] }}>
            {convertSmiliesToBBCode(content.replace(/\r\n|\r|\n/g, '[br]'))}
        </BBCode>
    ) : (
        <></>
    );
}
