import { ReactNode } from 'react';
import loadable from '@loadable/component';
import { Skeleton } from '@mui/material';
import { ErrorContainer } from './styles';

const Katex = loadable.lib(() => import('react-katex'));

interface LatexProps {
    children: ReactNode;
}

export default function Latex(props: LatexProps) {
    const latex = props.children?.toString() ?? '';
    return latex ? (
        <Katex fallback={<Skeleton width={100} variant="text" />}>
            {({ InlineMath }) => (
                <InlineMath
                    renderError={(error: any) => {
                        return (
                            <ErrorContainer>
                                <span>Unparseable latex formula:</span>
                                <p>{latex}</p>
                            </ErrorContainer>
                        );
                    }}
                >
                    {latex}
                </InlineMath>
            )}
        </Katex>
    ) : (
        <></>
    );
}
