import styled from '@emotion/styled';

export const Footnote = styled.span`
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: ${(props) => props.theme.grey.charcoal};
`;

export const Subtitle = styled.span`
    font-size: 18px;
    line-height: 20px;
`;

export const Title = styled.span`
    font-size: 24px;
    line-height: 26px;
`;
