import { memo, ReactNode } from 'react';
import PaginationBar from './PaginationBar';
import LazyLoad from '../LazyLoad';
import styled from '@emotion/styled';

interface PaginationProps {
    urlPrefix: string;
    currentPage: number;
    totalPages: number;
    itemsOnCurrentPage: number;
    children: ReactNode;
    isDisabled: boolean;
    onPagination?: Function;
    topMessage?: string;
    disableTopPagination?: boolean;
}

const TopPaginationBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const BottomPaginationBar = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ScrollMessage = styled.p`
    margin: ${(props) => props.theme.spacing(0, 0, 3, 0)};
    color: ${(props) => props.theme.text.secondary};
    ${(props) => props.theme.typography.subtitle2};
    text-transform: none;
`;

export default memo(
    ({
        urlPrefix,
        currentPage,
        totalPages,
        itemsOnCurrentPage,
        children,
        isDisabled,
        onPagination,
        topMessage,
        disableTopPagination
    }: PaginationProps) => {
        return (
            <>
                {totalPages > 1 && !disableTopPagination && (
                    <TopPaginationBar>
                        {topMessage && (
                            <ScrollMessage data-testid="top-pagination-message">
                                {topMessage}
                            </ScrollMessage>
                        )}
                        <PaginationBar
                            urlPrefix={urlPrefix}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            isDisabled={isDisabled}
                            onPagination={onPagination}
                            topPagination
                        />
                    </TopPaginationBar>
                )}

                {children}

                {totalPages > 1 && itemsOnCurrentPage > 0 && (
                    <LazyLoad key={'lazyload-bottom-pagination'}>
                        <BottomPaginationBar>
                            <PaginationBar
                                urlPrefix={urlPrefix}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                isDisabled={isDisabled}
                                onPagination={onPagination}
                            />
                        </BottomPaginationBar>
                    </LazyLoad>
                )}
            </>
        );
    }
);
