/**
 * @function safelyParseJSON
 * @param json JSON formatted as a string, i.e. from iframe postmessage
 * @returns If valid JSON, will return JSON object parsed from string. Otherwise returns null
 */
export default function safelyParseJSON(json: string) {
    var parsed;

    try {
        parsed = JSON.parse(json);
    } catch (e) {
        return null;
    }

    return parsed;
}
