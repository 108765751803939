interface ImageProps {
    src: string;
    alt?: string;
    title?: string;
    loading?: 'eager' | 'lazy';
    includeStructuredData?: boolean;
}

export default function Image(props: ImageProps) {
    return (
        <span
            data-testid='image-container'
            itemProp={props.includeStructuredData ? 'image' : undefined}
            itemType={props.includeStructuredData ? 'https://schema.org/ImageObject' : undefined}
            itemScope={props.includeStructuredData ? true : undefined}
        >
            <img
                src={props.src}
                alt={props.alt}
                title={props.title}
                loading={props.loading}
                itemProp={props.includeStructuredData ? 'contentUrl' : undefined}
            />
        </span>
    );
}
