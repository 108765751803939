import styled from '@emotion/styled';

export const ListContainer = styled.div`
    margin: ${(props) => props.theme.spacing(0)};
    padding: ${(props) => props.theme.spacing(0)};
`;

export const ListItemContainer = styled.div`
    margin: ${(props) => props.theme.spacing(2, 0, 2, 0)};
    padding: ${(props) => props.theme.spacing(0)};
    display: flex;
    flex-direction: row;
`;

export const ListItemBullet = styled.p`
    margin: ${(props) => props.theme.spacing(0, 2, 0, 2)};
    padding: ${(props) => props.theme.spacing(0)};
`;

export const ListItemContent = styled.p`
    margin: ${(props) => props.theme.spacing(0)};
    padding: ${(props) => props.theme.spacing(0)};
`;
