import { gql } from 'graphql-tag';

export const MESSAGES_PER_PAGE = 10;
export const CONVERSATIONS_PER_PAGE = 10;

export const GET_CONVERSATIONS = gql`
    query GetConversations($perPage: Int, $page: Int, $filters: [KeyValue]) {
        list: conversation_list(per_page: $perPage, page: $page, filters: $filters) {
            conversations {
                id
                title
                message
                users {
                    username
                    id
                    forum {
                        avatar
                    }
                }
                from_user {
                    id
                    username
                    forum {
                        avatar
                    }
                }
                updated_at
                unread_messages
            }
        }
    }
`;

export const GET_UNREAD_CONVERSATIONS = gql`
    query GetUnreadConversations {
        unread_conversation_count
    }
`;

export const GET_CONVERSATION = gql`
    query GetConversation($id: Int!) {
        conversation(id: $id) {
            id
            title
            message
            users {
                username
                id
                forum {
                    avatar
                }
            }
            from_user {
                id
                username
                forum {
                    avatar
                }
            }
            updated_at
            unread_messages
            pm_id
        }
    }
`;

export const GET_MESSAGES = gql`
    query GetMessages($perPage: Int, $page: Int, $filters: [KeyValue]) {
        list: message_list(per_page: $perPage, page: $page, filters: $filters) {
            total_results
            messages {
                id
                pm_id
                message
                from_user {
                    id
                    username
                    forum {
                        avatar
                    }
                }
                updated_at
                read
            }
        }
    }
`;

export const SEND_REPLY = gql`
    mutation SendReply(
        $title: String!
        $message: String!
        $replyId: Int!
        $recipients: [Recipient]
    ) {
        sendReply(title: $title, message: $message, replyId: $replyId, recipients: $recipients) {
            valid
            error
            conversation_id
            pm_id
            pm_text_id
        }
    }
`;
