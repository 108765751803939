import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';

export const ExpandContainer = styled.div`
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    background-color: ${(props) => props.theme.white};
    color: ${(props) => props.theme.text.secondary};
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const ExpandButton = styled(IconButton)`
    padding: ${(props) => props.theme.spacing(0)};
    color: ${(props) => props.theme.palette.success.main};
    border-radius: 15px;

    &:hover {
        color: ${(props) => props.theme.palette.success.dark};
    }
`;

export const ExpandedContent = styled.div`
    margin: ${(props) => props.theme.spacing(4)};
`;

export const ExpandHeader = styled.div<{ editable: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${(props) => props.theme.spacing(0)};
    padding: ${(props) => props.editable ? props.theme.spacing(1, 0) : props.theme.spacing(3)};
    background-color: ${(props) => props.editable ? props.theme.white : props.theme.palette.primary.main};
    cursor: ${(props) => props.editable ? 'auto' : 'pointer'};
`;

export const ExpandTitle = styled.p`
    ${(props) => props.theme.typography.subtitle1};
    color: ${(props) => props.theme.white};
    margin: ${(props) => props.theme.spacing(0)};
`;

export const ExpandInput = styled(TextField)`
    ${(props) => props.theme.typography.subtitle1};
    color: ${(props) => props.theme.text.primary};
    background-color: ${(props) => props.theme.white};
    margin: ${(props) => props.theme.spacing(3, 2)};
    width: 98%;

    &-MuiFormLabel-root {
        font-weight: bold;
        color: ${(props) => props.theme.palette.primary.main};
    }
`;
