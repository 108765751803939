// Libraries
import { Cookies } from 'react-cookie';
import { UserQualification } from 'types/user';


type DataCaptureUserType = {
    userId: number;
    userGroupId: number;
    career_phase: string;
    loading: boolean;
    last_updated?: string;
    current_learning_providers?: number[];
    current_subjects?: number[];
    future_learning_providers?: number[];
    future_subjects?: number[];
    gender?: string;
    mobile?: string;
    post_code?: string;
    current_qualifications?: UserQualification[];
    future_qualifications?: UserQualification[];
};

export default function showExistingMemberDPC(user: DataCaptureUserType, showNewExistingMemberDPC?: boolean) {
    let cookies = new Cookies();
    const dpcHiddenCookie = 'tsr_dpc_prompt_hidden';
    const userGroupsToShow = [2, 3, 4, 12];

    function isCompleted(data: any) {
        for (const [_, value] of Object.entries(data)) {
            if (!value || value === null) {
                return false;
            }
        }
        return true;
    }

    // If you're a guest, or not an established/probationary/unconfirmed member
    // Selected 'not studying' (qualification id 45 - this will be in future qualifications for international users)
    // Or you've got the modal dismiss cookie
    // Dont show the modal, return false
    if (
        user && (
            user.userId === 0 ||
            !userGroupsToShow.includes(user.userGroupId) ||
            user.current_qualifications?.some((item) => item.qualification_id === 45) ||
            user.future_qualifications?.some((item) => item.qualification_id === 45) ||
            cookies.get(dpcHiddenCookie)
        )
    ) {
        return '';
    }

    if (!user.loading) {
        // Parse the API date string into a Date object
        const lastUpdated =
            user?.last_updated && user?.last_updated.length > 0 && new Date(user?.last_updated);
        const lastUpdatedToIsoString = lastUpdated && lastUpdated.toISOString();
        // Get the current date
        const currentDate = new Date();
        // Check if the dates are the same
        const alreadyStartedToday =
            lastUpdatedToIsoString &&
            lastUpdatedToIsoString.slice(0, 10) === currentDate.toISOString().slice(0, 10);
        // Check for a cookie that indicates Data Capture has been seen in the last day
        const hiddenCookie = cookies.get(dpcHiddenCookie);

        // Get the timestamp of 3 months ago
        let d = new Date();
        d.setMonth(d.getMonth() - 3);
        const threeMonthsAgoTimestamp = d;
        // Check if data capture was last updated over 3 months ago
        const lastUpdatedOverThreeMonthsAgo =
            lastUpdatedToIsoString &&
            lastUpdatedToIsoString.slice(0, 10) <
                threeMonthsAgoTimestamp.toISOString().slice(0, 10);

        const isSeptOrOctober = currentDate.getMonth() === 8 || currentDate.getMonth() === 9;
        // Check if data capture was already updated in September or October of the current year
        const alreadyUpdatedInSeptOrOctober =
            lastUpdated &&
            (lastUpdated.getMonth() === 8 || lastUpdated.getMonth() === 9) &&
            lastUpdated.getFullYear() === currentDate.getFullYear();

        // Available profile fields for non-uni student
        const nonUniFlowDataCaptureObject = {
            current_subjects: user.current_subjects,
            end_year: user.current_qualifications?.some((item) => item.start_year !== null),
            future_learning_providers: user.future_learning_providers,
            future_subjects: user.future_subjects,
            intended_university_start_year: user?.future_qualifications?.find((item) => item.qualification_id === 28)?.start_year,
            qualifications: user.current_qualifications
        };

        // Available profile fields for uni student
        const uniFlowDataCaptureObject = {
            current_learning_providers: user.current_learning_providers,
            current_subjects: user.current_subjects,
            end_year: user.current_qualifications?.some((item) => item.start_year !== null),
            qualifications: user.current_qualifications
        };

        // Check for uni qualifications - Postgraduate, Undergraduate, Foundation degree
        const universityLevelQualifications = [29, 28, 12];
        const uniFlow = user?.current_qualifications?.some((item) => universityLevelQualifications.includes(item.qualification_id));

        // Check if any of the relevant data capture fields are null or empty string
        const isDataCaptureComplete =
            user && isCompleted(uniFlow ? uniFlowDataCaptureObject : nonUniFlowDataCaptureObject);

        /* Show the profile summary modal if:
        - It's September or October (for new academic year updating data)
        - The user has not already updated their data in September or October this year
        - The user has not dismissed Data Capture already */
        if (isSeptOrOctober && !alreadyUpdatedInSeptOrOctober && !hiddenCookie) {
            return showNewExistingMemberDPC ? 'existing_member' : 'dpc_prompt';
        }

        /* Show data capture if:
        - It's not September or October
        - The user has not already started Data Capture today
        - The user has not completed Data Capture or last completed Data Capture over 3 months ago
        - The user has not dismissed Data Capture already */
        if (
            !isSeptOrOctober &&
            !alreadyStartedToday &&
            (!isDataCaptureComplete || lastUpdatedOverThreeMonthsAgo) &&
            !hiddenCookie
        ) {
            return 'dpc_prompt';
        }

        return '';
    }
}
