import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const FieldContainer = styled(Box)`
    border-radius: ${(props) => props.theme.spacing(2)};
    width: 100%;
    border: 1px solid ${(props) => props.theme.outline};
`;

export const FieldTitle = styled.legend`
    font-weight: bold;
`;
