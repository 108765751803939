import { recordClick, recordVisible } from '@thestudentroom/datalayer';

// Track CTA clicks from reg modal
export const joinClicked: Function = (routeId: number) => {
    recordClick({
        group: 'registration',
        section: 'register-prompt',
        subsection: routeId.toString(), // Reg route ID in prod
        type: 'register',
        item: 'Join now'
    });
};

export const loginClicked: Function = (routeId: number) => {
    recordClick({
        group: 'registration',
        section: 'register-prompt',
        subsection: routeId.toString(), // Reg route ID in prod
        type: 'login',
        item: 'Login'
    });
};

export const closeClicked: Function = (routeId: number) => {
    recordClick({
        group: 'registration',
        section: 'register-prompt',
        subsection: routeId.toString(), // Reg route ID in prod
        type: 'close',
        item: 'Close'
    });
};

export function modalOpen(routeId: number) {
    recordVisible({
        group: 'registration',
        section: 'register-prompt',
        subsection: routeId.toString() // Reg route ID in prod
    });
}
