import { ChangeEvent, ReactNode, memo, useState } from 'react';
import Collapse from '@mui/material/Collapse';
import {
    ExpandContainer,
    ExpandHeader,
    ExpandTitle,
    ExpandButton,
    ExpandedContent,
    ExpandInput
} from './styles';
import useTranslation from '../../../hooks/useTranslation';
import { FaCircleChevronDown, FaCircleChevronUp } from 'react-icons/fa6';
import { DismissButton } from '../Quote/styles';
import { MdHighlightOff } from 'react-icons/md';
import { useAppTheme } from '@thestudentroom/lib.themes';
import { RootState } from 'client';
import { useSelector, useDispatch } from 'react-redux';
import { updateSavedReplies } from '../../../store/reply/actions';

interface ExpandProps {
    title: string;
    contents?: string;
    children: ReactNode;
    editable?: boolean;
    setExpandTitle?: Function;
    setExpandContents?: Function;
    savedReplyId?: string;
}

function Expand(props: ExpandProps) {
    const theme = useAppTheme();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const savedReplies = useSelector((state: RootState) => state.reply);
    const [isExpanded, setIsExpanded] = useState(props.editable);

    const handleExpand = () => {
        if (!props.editable) {
            setIsExpanded(!isExpanded);
        }
    };

    const handleDismissExpand = () => {
        if (!props.editable || !savedReplies || !savedReplies[props.savedReplyId ?? 'draft-reply']) {
            return;
        }

        const currentReply = savedReplies[props.savedReplyId ?? 'draft-reply'];
        const currentReplyContent = currentReply?.content ?? [];
        currentReply.content = currentReplyContent.filter(function (replyItem) {
            if (replyItem.type !== 'expand') {
                return true;
            }

            const replyExpandTitle = replyItem?.attrs?.expandTitle ?? '';
            const replyExpandContents = replyItem?.attrs?.expandContents ?? '';
            const matchesCurrentExpand = replyExpandTitle === props.title && replyExpandContents === (props.contents ?? '')
        
            return !matchesCurrentExpand;
        });

        dispatch(updateSavedReplies('draft-reply', currentReply));
    };

    const handleExpandTitleChange = (event: ChangeEvent<any>) => {
        if (!props.editable) return;

        props.setExpandTitle && props.setExpandTitle(event.target.value);
    };

    const handleExpandContentChange = (event: ChangeEvent<any>) => {
        if (!props.editable) return;

        props.setExpandContents && props.setExpandContents(event.target.value);
    };

    return (
        <ExpandContainer>
            <div style={{ width: props.editable ? '95%' : '100%' }}>
                <ExpandHeader onClick={handleExpand} editable={props.editable ?? false}>
                    {props.editable ?
                        <ExpandInput
                            data-testid={"expand-title-input"}
                            onInput={handleExpandTitleChange}
                            id={"expand-title"}
                            name={"Spoiler Title"}
                            label={"Spoiler Title"}
                            variant={"outlined"}
                            multiline={false}
                            type={'text'}
                            value={props.title}
                        />
                        :
                        <ExpandTitle
                            data-testid="expand-title"
                        >
                            {props.title.replace(/^'|'$|[|]/g, '')}
                        </ExpandTitle>
                    }

                    {!props.editable &&
                        <ExpandButton
                            onClick={handleExpand}
                            aria-label={isExpanded ? t('buttons.hide') : t('buttons.show')}
                            id={'expand-button'}
                        >
                            {isExpanded ? (
                                    <FaCircleChevronUp size={24} data-testid="collapse-icon" />
                                ) : (
                                    <FaCircleChevronDown size={24} data-testid="expand-icon" />
                                )}
                        </ExpandButton>
                    }
                </ExpandHeader>
                <Collapse in={isExpanded} unmountOnExit>
                    {props.editable ?
                        <ExpandInput
                            data-testid={"expand-content-input"}
                            onInput={handleExpandContentChange}
                            id={"expand-content"}
                            name={"Spoiler Content"}
                            label={"Spoiler Content"}
                            variant={"outlined"}
                            multiline={true}
                            type={'text'}
                            value={props.contents}
                        />
                        :
                        <ExpandedContent data-testid="expand-content">
                            {props.children}
                        </ExpandedContent>
                    }
                </Collapse>
            </div>

            {props.editable && (
                <DismissButton aria-label={'dismiss expand'} onClick={handleDismissExpand} $isExpandBlock={true}>
                    <MdHighlightOff size={20} color={theme.text.primary} />
                </DismissButton>
            )}
        </ExpandContainer>
    );
}

export default memo(Expand);
