import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import PaginationItem from '@mui/material/PaginationItem';
import { MouseEvent } from 'react';

// Components
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import NextNavItem from './PaginationButtons/NextNavItem';
import { PaginationTools } from './PaginationTools';
import { PaginationActionsContainer } from './styles';

// Tracking
import { recordClick } from '@thestudentroom/datalayer';

interface PaginationProps {
    currentPage: number | null;
    totalPages: number | null;
    urlPrefix: string | null;
    topPagination?: boolean;
    onPagination?: Function;
    isDisabled?: boolean;
}

export default function PaginationBar({
    currentPage,
    totalPages,
    urlPrefix,
    topPagination,
    onPagination,
    isDisabled = false
}: PaginationProps) {
    const isMobileOrTablet = useMediaQuery('(max-width: 670px)');
    const navigate = useNavigate();

    const urlPrefixHasQueryString = urlPrefix && urlPrefix.includes('?');
    const pageParamPrefix = urlPrefixHasQueryString ? '&' : '?';

    const paginate = (event: MouseEvent<HTMLElement>, item: any) => {
        event.preventDefault();
        if (typeof onPagination !== 'undefined') {
            setTimeout(() => {
                onPagination(item.page);
            }, 0);
        } else {
            setTimeout(() => {
                // use first/next/last if applicable, else use page number
                const pageParam = item.type === 'page' ? `Go to page ${item.page}` : item.type;

                recordClick({
                    group: 'navigating',
                    section: topPagination ? 'thread-tool-bar-top' : 'thread-tool-bar-bottom',
                    subsection: 'pagination',
                    item: pageParam
                });

                navigate(`${urlPrefix}${
                    item.page === 1 ? '' : `${pageParamPrefix}page=${item.page}`
                }`);
            }, 0);
        }
    };

    return (
        <>
            {currentPage ? (
                <>
                    <PaginationActionsContainer>
                        <Pagination
                            page={currentPage}
                            count={totalPages || 0}
                            hideNextButton={currentPage === totalPages || totalPages === 1}
                            hidePrevButton
                            siblingCount={1}
                            size={isMobileOrTablet ? 'small' : 'medium'}
                            renderItem={(item) => (
                                <PaginationItem
                                    component={RouterLink}
                                    components={{ next: NextNavItem }}
                                    to={`${urlPrefix}${
                                        item.page === 1 ? '' : `${pageParamPrefix}page=${item.page}`
                                    }`}
                                    {...item}
                                    disabled={item.page == currentPage || isDisabled}
                                    onClick={(event) => {
                                        paginate(event, item);
                                    }}
                                />
                            )}
                        />
                        {totalPages && totalPages > 5 && topPagination ? (
                            <PaginationTools
                                currentPage={currentPage}
                                urlPrefix={urlPrefix}
                                totalPages={totalPages}
                                isDisabled={isDisabled}
                                onPagination={onPagination}
                            />
                        ) : null}
                    </PaginationActionsContainer>
                </>
            ) : (
                <Skeleton variant="text" />
            )}
        </>
    );
}
