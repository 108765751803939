import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from '../Link';
import Button from '@mui/material/Button';
import { withTransientProps } from '../../helpers/transientStylingProp';

export const PollContainer = styled.div<{ $isThreadPoll: boolean }>`
    margin-top: ${(props) => (props.$isThreadPoll ? props.theme.spacing(2) : 0)};
    padding: ${(props) =>
        props.$isThreadPoll ? props.theme.spacing(3, 3, 5, 3) : props.theme.spacing(4)};
    border: 1px solid ${(props) => props.theme.palette.primary.light};
    border-radius: ${(props) => props.theme.spacing(2)};
    background-color: ${(props) => props.theme.palette.primary.light};

    @media (min-width: 671px) {
        margin-top: ${(props) => (props.$isThreadPoll ? props.theme.spacing(4) : 0)};
        padding: ${(props) =>
            props.$isThreadPoll ? props.theme.spacing(3, 4, 5, 4) : props.theme.spacing(5)};
    }
`;

export const PollHeader = styled.p`
    margin: ${(props) => props.theme.spacing(0, 0, 2)};
    color: ${(props) => props.theme.text.secondary};
    ${(props) => props.theme.typography.overline};
    text-transform: none;
`;

export const ClosedPollIndicator = styled.div<{ $isThreadPoll: boolean }>`
    width: 100%;
    margin: ${(props) =>
        props.$isThreadPoll ? props.theme.spacing(0, 0, 4) : props.theme.spacing(0, 0, 5)};
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: ${(props) => props.theme.spacing(5)};
    background-color: transparent;
    color: ${(props) => props.theme.text.primary};
    ${(props) => props.theme.typography.overline};
    text-align: center;
    border: 1px solid ${(props) => props.theme.text.primary};
    text-transform: none;
    font-size: 12px;
`;

export const PollQuestion = styled.h3<{ $isThreadPoll: boolean }>`
    margin: ${(props) =>
        props.$isThreadPoll ? props.theme.spacing(0, 0, 2) : props.theme.spacing(0, 0, 4)};
    color: ${(props) => props.theme.text.primary};
    ${(props) => props.theme.typography.h2};
`;

export const PollFooter = styled.div<{ $threadId?: number }>`
    display: flex;
    flex-direction: row;
    justify-content: ${(props) => (props.$threadId ? 'space-between' : 'end')};
    align-items: center;
    margin-top: ${(props) => props.theme.spacing(5)};
`;

export const TotalVotes = styled.span`
    color: ${(props) => props.theme.text.secondary};
    ${(props) => props.theme.typography.caption};
`;

export const ViewThreadLink = styled(Link)`
    ${(props) => props.theme.typography.subtitle2};
    letter-spacing: 0.84px;
    color: ${(props) => props.theme.palette.secondary.contrastText};

    &:hover {
        text-decoration: underline;
    }
`;

export const VoteButton = styled(Button)`
    border-color: ${(props) => props.theme.palette.primary.main};
    ${(props) => props.theme.typography.subtitle2};
    letter-spacing: 0.84px;
    color: ${(props) =>
        props.variant === 'outlined'
            ? props.theme.palette.secondary.contrastText
            : props.theme.white};
    background: ${(props) =>
        props.variant === 'outlined' ? 'transparent' : props.theme.palette.primary.main};
    box-shadow: none;
    text-transform: none;

    &:hover {
        border-color: ${(props) =>
            props.variant === 'outlined'
                ? props.theme.palette.primary.main
                : props.theme.palette.primary.dark};
        background: ${(props) =>
            props.variant === 'outlined' ? 'transparent' : props.theme.palette.primary.dark};
        box-shadow: none;
    }
`;

export const ResultToggleButton = styled(
    Button,
    withTransientProps
)<{
    $isThreadPoll: boolean;
}>(
    ({ theme, $isThreadPoll }) => css`
        width: 100%;
        min-height: 32px;
        box-shadow: none;
        border-radius: ${theme.spacing(5)};
        margin-top: ${theme.spacing(2)};
        justify-content: flex-start;
        padding: ${theme.spacing(1, 4)};
        border: none;
        background: ${theme.white};
        color: ${theme.text.primary};
        text-transform: none;
        text-align: left;
        cursor: 'pointer';
        vertical-align: middle;
        ${$isThreadPoll ? theme.typography.body2 : theme.typography.body1};

        &:hover {
            color: ${theme.white};
            background: ${theme.palette.primary.main};
        }
    `
);

export const ErrorMessage = styled.div`
    display: flex;
    margin-top: ${(props) => props.theme.spacing(2)};
    align-items: center;
    ${(props) => props.theme.typography.caption};
    color: ${(props) => props.theme.red};
    font-weight: 800;
    padding-left: 16px;
`;
