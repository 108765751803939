import styled from '@emotion/styled';

export const AccountsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 0;
    background-color: white;
    height: 100vh;
    @media (min-width: 600px) {
        background-color: #ededed;
        align-items: center;
        justify-content: center;
    }
`;
