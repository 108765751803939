import styled from '@emotion/styled';
import useTranslation from '../../../hooks/useTranslation';
import { MdBrokenImage } from 'react-icons/md';

const BrokenAttachmentContainer = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: ${(props) => props.theme.spacing(0, 3)};
    gap: ${(props) => props.theme.spacing(2)};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    background-color: ${(props) => props.theme.grey.main};
    white-space: nowrap;
    color: ${(props) => props.theme.text.secondary};
    ${(props) => props.theme.typography.body2};

    @media (min-width: 671px) {
        ${(props) => props.theme.typography.body1};
    }
`;

export default function BrokenAttachment() {
    const { t } = useTranslation();

    return (
        <BrokenAttachmentContainer>
            <MdBrokenImage
                size={20}
                data-testid="broken-attachment-icon"
            />
            {t('bbcode.attachment-not-found')}
        </BrokenAttachmentContainer>
    );
}
