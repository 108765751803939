import { ReactNode, useState, useCallback, useRef, useEffect } from 'react';
import useTranslation from '../../../hooks/useTranslation';
import Collapse from '@mui/material/Collapse';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    QuoteContainer,
    QuoteContent,
    OriginalPostInfo,
    ExpandButton,
    ExpandButtonContainer,
    DismissButton
} from './styles';
import { MdFormatQuote, MdHighlightOff } from 'react-icons/md';
import Link from '../../Link';
import { useAppTheme } from '@thestudentroom/lib.themes';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'client';
import { recordClick } from '@thestudentroom/datalayer';
import { updateSavedReplies } from '../../../store/reply/actions';

interface QuoteProps {
    user?: string;
    postId?: string;
    children: ReactNode;
    dismissable?: boolean;
    lineClampThreshold?: number;
    linesToShow?: number;
    trackingData?: {
        postId: number;
        postNumber: number;
        threadId: number;
        forumId: number;
        forumCategoryId: number;
    };
    isThreadOpen?: boolean;
}

export default function Quote(props: QuoteProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();
    const isMobile = useMediaQuery('(max-width:671px)');
    const savedReplies = useSelector((state: RootState) => state.reply);
    const dispatch = useDispatch();
    const dismissButtonRef = useRef<HTMLButtonElement>(null);
    const [dismissed, setDismissed] = useState(false);
    
    const [height, setHeight] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isClipped, setIsClipped] = useState(true);

    const handleExpandQuote = () => {
        setIsExpanded((prev) => !prev);
    };

    const setLineClamp = () => {
        setIsClipped((prev) => !prev);
    };

    const ref = useCallback((node) => {
        if (node) {
            setHeight(node.clientHeight);
        }
    }, []);

    const isImgFirst = () => {
        const child: { [key: string]: any } | null =
            (Array.isArray(props.children) ? props.children[0] : props.children) || null;
        return child && typeof child === 'object' && child['type'] == 'img';
    };

    // by default, if a quote is more than 4 lines on mobile or 2 lines on desktop,
    // it will be truncated to show 3 lines on mobile or 1 line on desktop
    const lineClampThreshold = props.lineClampThreshold || (isMobile ? 4 : 2);
    const linesToShow = props.linesToShow || (isMobile ? 3 : 1);

    // line height is 21px on mobile, 24px on desktop
    const heightThreshold = lineClampThreshold * (isMobile ? 21 : 24);
    const collapsedHeight = linesToShow * (isMobile ? 21 : 24);
    
    useEffect(() => {
        if (dismissed) {
            if (!savedReplies || !savedReplies['draft-reply']) {
                return;
            }
        
            recordClick({
                group: 'post-creation',
                section: 'post-reply-box',
                subsection: 'post',
                item: 'remove-quote',
                action: 'unquote',
                postId: props.trackingData?.postId ?? 0,
                position: props.trackingData?.postNumber ?? 0,
                threadId: props.trackingData?.threadId ?? 0,
                forumId: props.trackingData?.forumId ?? 0,
                forumCategoryId: props.trackingData?.forumCategoryId ?? 0
            });

            const currentReply = savedReplies['draft-reply'];
            const currentReplyContent = currentReply?.content ?? [];
            currentReply.content = currentReplyContent.filter(function (replyItem) {
                if (replyItem.type !== 'quote') {
                    return true;
                }
            
                return (replyItem?.attrs?.postId ?? 0) !== (props.postId ?? 0);
            });

            dispatch(updateSavedReplies('draft-reply', currentReply));
        }
    }, [dismissed]);

    const handleDismissQuote = async () => {
        if (props.dismissable && dismissButtonRef.current) {
            dismissButtonRef.current?.focus();
            setDismissed(true);
        }
    };

    const originalPoster = () => {
        if (props.postId) {
            return (
                <div key={`original-post-link-${props.postId}`} style={{ width: '95%' }}>
                    <Link
                        url={'/showthread.php?p=' + props.postId + '#post' + props.postId}
                    >
                        Original post
                    </Link>{' '}
                    by {props.user}
                </div>
            );
        }
        return props.user;
    };

    return (
        <QuoteContainer
            key={`quote-container-${props.postId}`}
            data-testid="quote-container"
            isThreadOpen={props.isThreadOpen ?? true}
        >
            <MdFormatQuote
                key={`quote-icon-${props.postId}`}
                size={40}
                color={(props.isThreadOpen ?? true) ? theme.palette.primary.main : theme.grey.mid}
                style={{
                    transform: 'rotate(180deg)',
                    marginTop: theme.spacing(1),
                    marginRight: theme.spacing(2)
                }}
            />

            <QuoteContent key={`quote-content-container-${props.postId}`}>
                <OriginalPostInfo key={`original-post-info-${props.postId}`}>{props.user ? originalPoster() : <div style={{ height: '10px' }}></div>}</OriginalPostInfo>

                {height > heightThreshold || props.dismissable ? (
                    <ExpandButtonContainer key={`quote-expand-container-${props.postId}`} isExpanded={isExpanded} lines={linesToShow}>
                        <Collapse
                            key={`quote-collapse-${props.postId}`}
                            in={isExpanded}
                            collapsedSize={collapsedHeight}
                            timeout={500}
                            addEndListener={setLineClamp} // after transition, set the new line cutoff
                            className={
                                isClipped ? (isImgFirst() ? 'ImgFirst ClipQuote' : 'ClipQuote') : ''
                            }
                        >
                            {props.children}
                        </Collapse>

                        <ExpandButton
                            key={`quote-expand-button-${props.postId}`}
                            onClick={handleExpandQuote}
                            aria-label={
                                isExpanded ? t('buttons.see-less') : t('buttons.see-more')
                            }
                        >
                            {isExpanded ? t('buttons.see-less') : t('buttons.see-more')}
                        </ExpandButton>
                    </ExpandButtonContainer>
                ) : (
                    <div key={`quote-content-${props.postId}`} ref={ref}>
                        {props.children}
                    </div>
                )}
            </QuoteContent>

            {props.dismissable && (
                <DismissButton
                    key={`dismiss-quote-${props.postId}`}
                    ref={dismissButtonRef}
                    aria-label={'dismiss quote'}
                    onClick={handleDismissQuote}
                    $isExpandBlock={false}
                >
                    <MdHighlightOff
                        key={`dismiss-quote-icon-${props.postId}`}
                        size={20}
                        color={theme.text.primary}
                    />
                </DismissButton>
            )}
        </QuoteContainer>
    );
}
