import { gql } from 'graphql-tag';

export const PER_PAGE = 20;

export const GET_THREAD = gql`
    query GetThread($threadId: Int!, $isMember: Boolean!) {
        thread(id: $threadId) {
            id
            status_code
            redirect_url
            page
            title
            n_posts
            forums {
                id
                title
                uni_course
                subject
                uni_college
                page_category
                edu_level
                allow_anonymous
            }
            first_post {
                id
            }
            last_post {
                id
                created_at
            }
            poll_id
            open
            open_status
            closed_date
            no_indexed
            topics
            page_topic_codes
            main_page_topic_codes
            tags {
                group
                tag
                short_tag
                tsrg_id
                confidence
                relevance
                sort
            }
            watched @include(if: $isMember)
            posted_anonymously @include(if: $isMember)

            learning_providers {
                id
                name
                slug
                content {
                    Media {
                        LogoImage {
                            name
                            url
                            alt
                            caption
                            width
                            height
                        }
                    }
                }
                lead_generation {
                    visit_website
                    request_info
                    open_days
                    prospectus
                }
                did_you_know_facts {
                    id
                    Fact
                }
                approved_reviews {
                    id
                    RatingOverall
                    ReviewOverall
                    CurrentYear
                }
            }

            brand_awareness_blocks {
                id
                LearningProvider {
                    id
                    name
                    slug
                    content {
                        Media {
                            LogoImage {
                                name
                                url
                                alt
                                caption
                                width
                                height
                            }
                        }
                    }
                    did_you_know_facts {
                        id
                        Fact
                    }
                    approved_reviews {
                        id
                        RatingOverall
                        ReviewOverall
                        CurrentYear
                    }
                }
            }

            partnership_blocks {
                id
                BrandName
                Image {
                    url
                    alt
                    caption
                    width
                    height
                }
                Text
                URL
                LearningProvider {
                  id
                  name
                  slug
                  content {
                    Media {
                      LogoImage {
                        name
                        url
                        alt
                        caption
                        width
                        height
                      }
                    }
                  }
                }
            }
        }
    }
`;

export const GET_FIRST_POST = gql`
    query GetFirstPost($threadId: Int!, $isMember: Boolean!) {
        posts(threadId: $threadId, page: 1, perPage: 1) {
            id
            created_at
            updated_at
            rep_count
            content
            has_attachments
            updated_by_user {
                id
                username
            }
            user {
                id
                username
                is_anonymous
                forum {
                    avatar
                    n_posts
                    n_rep
                    rep_level
                    n_badges
                    n_followers
                    join_datetime
                    last_activity_datetime
                    last_visit_datetime
                    usergroups {
                        id
                        usertitle
                        primary
                        display
                        description
                    }
                    online
                    title
                }
            }
            repped_by_user @include(if: $isMember)
        }
    }
`;

export const GET_THREAD_PAGE = gql`
    query GetThreadPage($threadId: Int!, $pageNumber: Int!, $isMember: Boolean!) {
        posts(threadId: $threadId, page: $pageNumber) {
            id
            created_at
            updated_at
            rep_count
            content
            has_attachments
            updated_by_user {
                id
                username
            }
            user {
                id
                username
                is_anonymous
                forum {
                    avatar
                    n_posts
                    n_rep
                    rep_level
                    n_badges
                    n_followers
                    join_datetime
                    last_activity_datetime
                    last_visit_datetime
                    usergroups {
                        id
                        usertitle
                        primary
                        display
                        description
                    }
                    online
                    title
                }
            }
            repped_by_user @include(if: $isMember)
        }
    }
`;

export const GET_POST_CONTENT = gql`
    query GetPostContent($postId: Int!, $forumId: Int!) {
        postContent(postId: $postId, forumId: $forumId) {
            content
        }
    }
`;

export const GET_PAGE_NUMBER = gql`
    query GetPageNumber($postId: Int!, $perPage: Int!, $threadId: Int) {
        page(postId: $postId, perPage: $perPage, threadId: $threadId) {
            thread_id
            page_number
        }
    }
`;

export const GET_LATEST_THREADS = gql`
    query GetLatestThreads(
        $perPage: Int
        $page: Int
        $orderBy: String
        $orderByDirection: String
        $filters: [KeyValue]
        $includeFirstPost: Boolean = false
    ) {
        threads(
            per_page: $perPage
            page: $page
            order_by: $orderBy
            order_by_direction: $orderByDirection
            filters: $filters
        ) {
            id
            title
            n_posts
            subscribers
            forumid
            first_post @include(if: $includeFirstPost) {
                id
                created_at
                user {
                    id
                    username
                    is_anonymous
                    forum {
                        avatar
                        n_posts
                        n_rep
                        rep_level
                        n_badges
                        n_followers
                        join_datetime
                        last_activity_datetime
                        last_visit_datetime
                        usergroups {
                            id
                            usertitle
                            primary
                            display
                            description
                        }
                        online
                        title
                    }
                }
            }
            last_post {
                created_at
            }
            topics            
            forums {
                id
                title
            }
        }
    }
`;

export const GET_WATCHED_TOPIC_THREADS = gql`
    query Watched_topic_list(
        $perPage: Int
        $page: Int
        $orderBy: String
        $orderByDirection: String
        $filters: [KeyValue]
    ) {
        watched_topic_list(
            per_page: $perPage
            page: $page
            order_by: $orderBy
            order_by_direction: $orderByDirection
            filters: $filters
        ) {
            watched {
                watched_topic {
                    id
                    title
                    latest_thread {
                        id
                        title
                        first_post {
                            user {
                                id
                                username
                                is_anonymous
                                forum {
                                    avatar
                                    n_posts
                                    n_rep
                                    n_badges
                                    rep_level
                                    n_followers
                                    join_datetime
                                    last_activity_datetime
                                    last_visit_datetime
                                    usergroups {
                                        id
                                        usertitle
                                        primary
                                        display
                                        description
                                    }
                                    online
                                    title
                                }
                            }
                        }
                        last_post {
                            created_at
                        }
                        n_posts
                        subscribers
                    }
                }
            }
            total_results
        }
    }
`;

export const GET_WATCHED_THREADS = gql`
    query GetWatchedThreads(
        $perPage: Int
        $page: Int
        $orderBy: String
        $orderByDirection: String
        $filters: [KeyValue]
    ) {
        threads(
            per_page: $perPage
            page: $page
            order_by: $orderBy
            order_by_direction: $orderByDirection
            filters: $filters
        ) {
            id
            title
            n_posts
            subscribers
            forumid
            first_post {
                user {
                    id
                    username
                    is_anonymous
                    forum {
                        avatar
                        n_posts
                        n_rep
                        rep_level
                        n_badges
                        n_followers
                        join_datetime
                        last_activity_datetime
                        last_visit_datetime
                        usergroups {
                            id
                            usertitle
                            primary
                            display
                            description
                        }
                        online
                        title
                    }
                }
                created_at
            }
            last_post {
                created_at
            }
            topics
            forums {
                id
                title
            }
        }
    }
`;

export const GET_RELATED_THREADS = gql`
    query GetRelatedThreads($threadId: Int!) {
        relatedThreads(threadId: $threadId) {
            id
            title
            forumid
            boosted
        }
    }
`;

export const GET_TRENDING_THREADS = gql`
    query GetTrendingThreads($threadId: Int!, $includeFirstPost: Boolean = false) {
        trendingThreads(threadId: $threadId) {
            id
            title
            forumid
            n_posts
            subscribers
            first_post @include(if: $includeFirstPost) {
                id
                created_at
                user {
                    id
                    username
                    is_anonymous
                    forum {
                        avatar
                        n_posts
                        n_rep
                        rep_level
                        n_badges
                        n_followers
                        join_datetime
                        last_activity_datetime
                        last_visit_datetime
                        usergroups {
                            id
                            usertitle
                            primary
                            display
                            description
                        }
                        online
                        title
                    }
                }
            }
            last_post {
                created_at
            }
            forums {
                id
                title
            }
        }
    }
`;

export const GET_RELATED_ARTICLES = gql`
    query GetRelatedArticles($forumId: Int!) {
        relatedArticles(forumId: $forumId) {
            id
            content_id
            image_url
            link_text
            url
            website
        }
    }
`;

export const GET_RELATED_ARTICLES_SEARCH = gql`
    query GetRelatedArticlesSearch($query: String!) {
        searchRelatedArticles(query: $query) {
            id
            image_url
            link_text
            url
            website
            is_promoted
        }
    }
`;

export const GET_ATTACHMENTS = gql`
    query GetAttachments($postId: Int!) {
        attachments(postId: $postId) {
            id
            url
            file_name
            file_size
            file_type
        }
    }
`;

export const GET_ATTACHMENT = gql`
    query GetAttachment($attachmentId: Int!) {
        attachment(attachmentId: $attachmentId) {
            id
            url
            file_name
            file_size
            file_type
        }
    }
`;

export const GET_INSTITUTION = gql`
    query GetInstitution($forumId: Int!) {
        institution(forumId: $forumId) {
            id
            name
            slug
            address
            images
            lead_generation
        }
    }
`;

export const GET_ANNOUNCEMENTS = gql`
    query GetAnnouncements($forumIds: [Int!]!) {
        announcements(forumIds: $forumIds) {
            id
            title
            important
        }
    }
`;

export const GET_RELEVANT_FORUMS = gql`
    query GetRelevantForums($query: String!) {
        relevantForums(query: $query) {
            id
            categoryId
            title
            icon
            allowAnonymous
        }
    }
`;

export const GET_NEW_POST = gql`
    query GetNewPost($threadId: Int!) {
        threadNewPost(threadId: $threadId) {
            thread_id
            page_number
            post_id
        }
    }
`;

export const DELETE_POST = gql`
    mutation DeletePost($postId: Int!) {
        deletePost(postId: $postId) {
            success
            error
        }
    }
`;

export const GET_POSTS = gql`
    query GetPosts(
        $perPage: Int
        $page: Int
        $orderBy: String
        $orderByDirection: String
        $filters: [KeyValue]
    ) {
        post_list(
            per_page: $perPage
            page: $page
            order_by: $orderBy
            order_by_direction: $orderByDirection
            filters: $filters
        ) {
            posts {
                id
                thread_id
                thread_title
                thread_n_posts
                thread_subscribers
                content
                created_at
                updated_at
                rep_count
                user {
                    id
                    username
                    is_anonymous
                    forum {
                        avatar
                        n_posts
                        n_rep
                        rep_level
                        n_badges
                        n_followers
                        join_datetime
                        last_activity_datetime
                        last_visit_datetime
                        usergroups {
                            id
                            usertitle
                            primary
                            display
                            description
                        }
                        online
                        title
                    }
                }
                is_anonymous
                repped_by_user
                forums {
                    id
                    title
                }
            }
            total_results
        }
    }
`;

export const GET_LATEST_THREAD_PER_FORUM = gql`
    query GetForums($perPage: Int, $page: Int, $filters: [KeyValue]) {
        list: forum_list(page: $page, per_page: $perPage, filters: $filters) {
            forums {
                lastthreadid
            }
        }
    }
`;

export const LOG_POST_ERROR = gql`
    mutation LogPostError(
        $userId: Int!, 
        $threadId: Int!, 
        $postText: String!, 
        $errorMessage: String!, 
        $exceptionDetails: String!, 
        $app: String!,
        $codeLocation: String!,
        $requestURL: String,
        $requestMethod: String,
        $requestHeaders: String,
        $requestBody: String,
        $responseHeaders: String,
        $responseBody: String,
        $responseStatusCode: Int
    ) {
        post_error_log(
            userId: $userId, 
            threadId: $threadId, 
            postText: $postText, 
            errorMessage: $errorMessage, 
            exceptionDetails: $exceptionDetails, 
            app: $app,
            codeLocation: $codeLocation,
            requestURL: $requestURL,
            requestMethod: $requestMethod,
            requestHeaders: $requestHeaders,
            requestBody: $requestBody,
            responseHeaders: $responseHeaders,
            responseBody: $responseBody,
            responseStatusCode: $responseStatusCode
        ) 
    }
`;
