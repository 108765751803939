import { gql } from 'graphql-tag';

export const GET_POLL = gql`
    query GetPoll($pollId: Int!) {
        poll(id: $pollId) {
            id
            question
            open
            options {
                text
                n_votes
            }
            show_results
            voted
            multiple
            can_vote
            num_of_votes
        }
    }
`;

export const GET_TSR_POLL = gql`
    query GetTSRPoll {
        tsrPoll {
            id
            question
            open
            options {
                text
                n_votes
            }
            thread_id
            show_results
            voted
            multiple
            can_vote
            num_of_votes
        }
    }
`;

export const POLL_VOTE = gql`
    mutation PollVote($pollId: Int!, $optionIds: [Int!]!) {
        pollVote(pollId: $pollId, optionIds: $optionIds) {
            success
            error
        }
    }
`;
