import styled from '@emotion/styled';
import Link from '.';

export const WidgetLink = styled(Link)`
    ${(props) => props.theme.typography.subtitle2};
    margin-left: 1px;
    border-radius: ${(props) => props.theme.spacing(1)};
`;

export const InlineLink = styled(Link)`
    ${(props) => props.theme.typography.subtitle1};
    color: ${(props) => props.theme.palette.primary.main};
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.palette.primary.main};
    border-radius: ${(props) => props.theme.spacing(1)};
    &:hover {
        color: ${(props) => props.theme.palette.primary.dark};
        text-decoration-color: ${(props) => props.theme.palette.primary.dark};
    }
`;

export const InlineAnchorTag = styled.a`
    ${(props) => props.theme.typography.subtitle2};
    color: ${(props) => props.theme.palette.primary.main};
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.palette.primary.main};
    border-radius: ${(props) => props.theme.spacing(1)};
    &:hover {
        color: ${(props) => props.theme.palette.primary.dark};
        text-decoration-color: ${(props) => props.theme.palette.primary.dark};
    }

    @media (min-width: 671px) {
        ${(props) => props.theme.typography.subtitle1};
    }
`;
