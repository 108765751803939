import styled from '@emotion/styled';

interface VideoProps {
    src: string;
}

const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; // calculated for video with 16:9 aspect ratio
`;

const VideoIframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export default function Video(props: VideoProps) {
    return (
        <VideoContainer
            data-testid='video-container'
        >
            <VideoIframe
                src={props.src}
                frameBorder="0"
            />
        </VideoContainer>
    );
}
