import { memo } from 'react';
import ImageList from '@mui/material/ImageList';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    OuterRelatedItemsWidgetContainer,
    RelatedItemsWidgetContainer,
    RelatedItemsWidgetHeader,
    RelatedItemsWidgetTitle
} from './styles';
import loadable from '@loadable/component';
import type { RelatedItemType } from './types';
import { useAppTheme } from '@thestudentroom/lib.themes';

interface ViewProps {
    title: string | React.ReactElement;
    cta?: React.ReactElement;
    items: RelatedItemType[];
    itemType?: string;
    trackingCallback: (item: RelatedItemType, index: number) => void;
    standalone: boolean;
    location?: string;
    numColumns?: number;
}

const RelatedItem = loadable(() => import('./RelatedItem'));

export default memo((props: ViewProps) => {
    const theme = useAppTheme();
    const isDesktop = useMediaQuery('(min-width: 671px)');
    const numColumns = props.numColumns ? props.numColumns : isDesktop ? 4 : 2; // images arranged 1x4 on desktop and tablet, and 2x2 on mobile unless specified.
    const gap = parseInt((isDesktop ? theme.spacing(2) : theme.spacing(6)).replace('px', ''));

    return (
        <OuterRelatedItemsWidgetContainer>
            <RelatedItemsWidgetContainer standalone={props.standalone} data-cy="related-articles">
                <RelatedItemsWidgetHeader>
                    <RelatedItemsWidgetTitle variant="h3" location={props.location}>
                        {props.title}
                    </RelatedItemsWidgetTitle>
                    {props.cta ?? null}
                </RelatedItemsWidgetHeader>
                <ImageList cols={numColumns} gap={gap} sx={{ margin: 0 }}>
                    {props.items.map((item: RelatedItemType, index: number) => (
                        <RelatedItem
                            key={item.id}
                            index={index}
                            item={item}
                            itemType={props.itemType}
                            trackingCallback={props.trackingCallback}
                        />
                    ))}
                </ImageList>
            </RelatedItemsWidgetContainer>
        </OuterRelatedItemsWidgetContainer>
    );
});
