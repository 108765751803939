import { supportedTags } from '../../components/BBCodeParser/supportedTags';
import { faces, smilies } from './smilies';

export default function convertSmiliesToBBCode(text: string) {
    return (
        // Step 1.
        // If a smilie is present inside a bbcode tag, for example [quote=username:smile:] or [quote=username:)], then it breaks.
        // A solution is to give these smilies temporary tags to prevent them being assigned [s] tags in Step 2.
        text
            .replace(bbcodeTagsRegex, (match) => {
                return (
                    // e.g. [quote=username:smile:] becomes [quote=username[excludedSmilie]smile[/excludedSmilie]]
                    match
                        .replace(
                            smiliesRegex,
                            (match) =>
                                `[excludedSmilie]${match.substring(
                                    1,
                                    match.length - 1
                                )}[/excludedSmilie]`
                        )
                        // e.g. [quote=username:)] becomes [quote=username[excludedFace]smile[/excludedFace]]
                        .replace(
                            facesRegex,
                            (match) => `[excludedFace]${faces[match]}[/excludedFace]`
                        )
                );
            })
            // Step 2.
            // Replace :smilie: with [s]smilie[/s]
            .replace(smiliesRegex, (match) => `[s]${match.substring(1, match.length - 1)}[/s]`)
            // Replace faces e.g. :) with [s]smile[/s]
            .replace(facesRegex, (match) => `[s]${faces[match]}[/s]`)

            // Step 3.
            // Smilies with excluded tags should return to their original form and will be ignored by the BBCodeParser.
            // e.g. [quote=username[excludedSmilie]smile[/excludedSmilie]] returns to [quote=username:smile:]
            .replace(/\[excludedSmilie\]/gi, ':')
            .replace(/\[\/excludedSmilie\]/gi, ':')
            // e.g. [quote=username[excludedFace]smile[/excludedFace]] returns to [quote=username:)]
            .replace(
                /\[excludedFace\](.*?)\[\/excludedFace\]/gi,
                (match, face) => Object.keys(faces).find((key) => faces[key] === face) || ''
            )
    );
}

// Build a regex string for supported bbcode tags: /[(quote|expand|url|...)=(.*?)]/g
const bbcodeTagsRegex = new RegExp('\\[(' + supportedTags.join('|') + ')=(.*?)\\]', 'g');

// 1. Map over the keys and escape brackets and dollar signs
// 2. Join the keys with | to create the following regex string: /:\)|:\(|:p|;\)|:D|:o|:S|:@|:\$|:E|:U|:H|:#/g
const facesRegex = new RegExp(
    Object.keys(faces)
        .map((key) => key.replace(/([()$])/g, '\\$1'))
        .join('|'),
    'g'
);

// Escape any special characters used in smilies
const escapedSmilies = smilies.map((smilie) => smilie.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'));

// Build a regex string for supported smilies: /:(smile|frown|tongue|wink...):/g
const smiliesRegex = new RegExp(':(' + escapedSmilies.join('|') + '):', 'g');
