export const faces: { [id: string]: string } = {
    ':)': 'smile',
    ':(': 'frown',
    ':p': 'tongue',
    ';)': 'wink',
    ':D': 'biggrin',
    ':o': 'redface',
    ':S': 's-smilie',
    ':@': 'angry',
    ':$': 'colondollar',
    ':E': 'colone',
    ':U': 'u',
    ':H': 'h',
    ':#': 'colonhash'
};

export const smilies = [
    '007',
    '2012',
    '2in1',
    '^_^',
    'absinth',
    'acaila',
    'adore',
    'aetsch',
    'afraid',
    'ahee',
    'ahhhhh',
    'albertein',
    'angel',
    'angelblush',
    'angelwings',
    'angry',
    'animal',
    'argh',
    'arsenal',
    'ashamed2',
    'ashamed',
    'askamod',
    'auto',
    'awesome',
    'backstab',
    'badger',
    'banana2',
    'banana',
    'bandit',
    'banghead',
    'banned',
    'bath',
    'battle',
    'bawling',
    'bear',
    'beard',
    'bebored',
    'bee2',
    'bee',
    'beer',
    'beerglass',
    'begone',
    'bell',
    'biggrin',
    'bigsmile',
    'bike',
    'bird',
    'birthday',
    'bl',
    'blah',
    'blow',
    'blush',
    'blushing',
    'boing',
    'bootyshake',
    'borat',
    'bored',
    'boring',
    'boxing',
    'bricks',
    'broken',
    'bubbles',
    'bump',
    'bumps',
    'bunny2',
    'bunny',
    'burnout',
    'cake',
    'call2',
    'call',
    'candle',
    'candycane',
    'cannon',
    'captain',
    'catfight',
    'centipe',
    'chaplin',
    'charm',
    'cheeky',
    'cheers',
    'chicken',
    'ciao',
    'cig',
    'clap2',
    'claus',
    'clip',
    'closedeyes',
    'cockup',
    'coffee',
    'colondollar',
    'colone',
    'colonhash',
    'coma',
    'confused',
    'congrats',
    'console',
    'cookie',
    'cool',
    'couchpotato',
    'cracker',
    'crast',
    'crazy',
    'creep',
    'crossedf',
    'crown',
    'cry2',
    'cry',
    'cube',
    'curious',
    'cute',
    'cyber',
    'damnmate',
    'dance',
    'danceboy',
    'dancing2',
    'dancing',
    'date',
    'daydreaming',
    'deal',
    'desu',
    'devil3',
    'devil',
    'dice',
    'diep',
    'dipsy',
    'ditto',
    'doctor',
    'dogt',
    'doh',
    'dolphin',
    'dong',
    'dontknow',
    'donut',
    'dots',
    'driver',
    'drool',
    'dry',
    'dumbells',
    'dunce',
    'eating',
    'eek2',
    'eek3',
    'eek4',
    'eek',
    'egg',
    'elefant',
    'elmo',
    'embarrassed',
    'emo',
    'emog',
    'england',
    'erm',
    'evil',
    'evilbanana',
    'eviltongue',
    'excited',
    'eyeball',
    'eyebrow',
    'f1',
    'facepalm2',
    'facepalm',
    'fallenangel',
    'fan',
    'fangs',
    'faq',
    'fart',
    'fatcontroller',
    'ff2',
    'ff',
    'fight',
    'fisheye',
    'five',
    'flamingo',
    'fleurdelis',
    'flip',
    'flower2',
    'flower',
    'flowers',
    'fluffy',
    'flute',
    'flutter',
    'flutterby',
    'flybye',
    'football',
    'france',
    'frown',
    'fyi',
    'gah',
    'gasp',
    'getmecoat',
    'gfight',
    'gigg',
    'giggle',
    'girl',
    'girlhit',
    'goaway',
    'godancing',
    'goodluck',
    'goodnight',
    'goofy',
    'google',
    'grin',
    'groovy',
    'grouphugs',
    'grr',
    'grumble',
    'gthumb',
    'h',
    'hahaha',
    'hand',
    'handkiss',
    'happy2',
    'hare',
    'hat2',
    'hat',
    'haughty',
    'headbang',
    'headfire',
    'heart',
    'hellloo',
    'hello',
    'help',
    'hi',
    'hide',
    'hippie',
    'hitit',
    'hmmm',
    'hmmmm2',
    'hmmmm',
    'hmpf',
    'hnn',
    'holly2',
    'holly',
    'holmes',
    'hoppy',
    'horns',
    'horse',
    'hubba',
    'huff',
    'hugs',
    'hugwings',
    'idea2',
    'idea',
    'iiam',
    'ike',
    'indiff',
    'innocent',
    'insert',
    'israel',
    'itsme',
    'jacko',
    'jerry',
    'jester',
    'jfault',
    'jiggy',
    'jitsu',
    'jive',
    'joyman',
    'judge',
    'juggle',
    'jumphug',
    'K',
    'kart',
    'keyboard',
    'king1',
    'king2',
    'king3',
    'kiss2',
    'kiss',
    'kissing2',
    'kitty',
    'knuddel',
    'koala',
    'kungfu',
    'lala',
    'laugh',
    'lazy',
    'lep',
    'lfc2',
    'lfc',
    'lies',
    'lips',
    'listen',
    'littleangel',
    'llama',
    'lol',
    'lollypop',
    'lolwut',
    'lolz',
    'london',
    'love',
    'loveduck',
    'lovedup2',
    'lovedup',
    'lovehug',
    'lovie2',
    'lovie',
    'lttd',
    'lurk',
    'macarena',
    'mad2',
    'mad',
    'manutd',
    'mario',
    'medieval',
    'merry',
    'merryxmas',
    'mistle',
    'mistletoe',
    'mmm',
    'mob',
    'moo',
    'moon',
    'motz',
    'mrfreeze',
    'mrssanta',
    'mrt',
    'ms',
    'muse',
    'music',
    'musicus',
    'mute',
    'mwuaha',
    'nah',
    'naughty',
    'ninja2',
    'ninja',
    'ninjagirl',
    'nn',
    'no',
    'nodots',
    'noonoo',
    'nooo',
    'nope',
    'nopity',
    'nosebleed',
    'nospam',
    'nothing',
    'noway',
    'nt',
    'nurse',
    'nutcase',
    'nuts',
    'nyan',
    'nyoron',
    'o',
    'oink',
    'oops',
    'ottid',
    'own3d',
    'p',
    'pain',
    'pal2',
    'pal',
    'parrot',
    'party2',
    'party',
    'pcguru',
    'pd',
    'pee',
    'penguin',
    'penguinhug',
    'pepsi',
    'perv',
    'pierre',
    'pig2',
    'piggy',
    'pika',
    'pinch',
    'pirate2',
    'pirate3',
    'pirate',
    'pirateprincess',
    'pizza',
    'playball',
    'please',
    'plugin',
    'plz2',
    'pms',
    'po',
    'poke',
    'poker',
    'poo',
    'popout',
    'present',
    'pride',
    'proud',
    'puke',
    'punct',
    'punk',
    'puppyeyes',
    'pwnd',
    'qed',
    'question',
    'rambo',
    'rant',
    'rave',
    'reaper',
    'red',
    'reddevil',
    'reddy',
    'redface',
    'redrose',
    'reel',
    'reindeer',
    'rip',
    'rock',
    'rofl2',
    'rofl3',
    'rofl',
    'rolleyes',
    'rose',
    'run',
    'russia',
    's-smilie',
    's',
    'sad',
    'sadnod',
    'sadpanda',
    'santa2',
    'santa3',
    'scales',
    'scrooge',
    'scuba',
    'security',
    'sexface',
    'shades',
    'shakecane',
    'shakehand',
    'sheep',
    'shh',
    'shifty',
    'shock',
    'shoot',
    'shot',
    'shy2',
    'sick',
    'sigh',
    'silverwings',
    'slap',
    'sleep',
    'sly',
    'smartass',
    'smile',
    'smokin',
    'smug',
    'smurf',
    'sneakydevil',
    'snoozing',
    'snow',
    'snowm',
    'snowman2',
    'snowman',
    'soap',
    'sogood',
    'sorry',
    'sparta',
    'spider',
    'sports',
    'ssaw',
    'star',
    'starwars',
    'stickyman',
    'stink',
    'stomp',
    'stupid',
    'stupido2',
    'stupido3',
    'stupido',
    'sub2',
    'sub',
    'subjontif',
    'sucks',
    'suitc',
    'suitd',
    'suith',
    'suits',
    'ta',
    'talkhand',
    'taz',
    'tea',
    'teddy',
    'tee',
    'teehee',
    'teeth',
    'thrasher',
    'thumbdown',
    'thumbsdown',
    'thumbsup',
    'timewarp',
    'tinkywinky',
    'titan',
    'toilet',
    'tomato',
    'tong',
    'tongue',
    'toofunny',
    'top2',
    'top',
    'topic',
    'train2',
    'train',
    'tree',
    'troll2',
    'troll',
    'trytofly',
    'tsr2',
    'tsr',
    'tumble',
    'turtle',
    'tux',
    'u',
    'unimpressed',
    'unsure',
    'vader',
    'viking',
    'virtual_bar',
    'vroam',
    'w00t',
    'waffo',
    'wannastop',
    'wavey',
    'wavingtheflag',
    'whip2',
    'whip',
    'wings',
    'wink2',
    'wink',
    'withstupid',
    'wizard2',
    'wizard',
    'womensoc',
    'woo',
    'woohoo',
    'work',
    'worm2',
    'worm',
    'wtf',
    'xmas',
    'xmasgrin',
    'y',
    'yawn',
    'yay',
    'yeah',
    'yep',
    'yes',
    'yikes',
    'ymca',
    'youbetcha',
    'yucky',
    'yum',
    'yy',
    'zomg',
    'zorro'
];

/* 
    Censored emoticons - do not re-add:
        [s]2euk48l[/s]    
        [s]afro[/s]   
        [s]bban[/s] 
        [s]bhangra[/s]
        [s]bong[/s]
        [s]bong2[/s]
        [s]camp[/s]
        [s]fuhrer[/s]
        [s]gangster[/s]
        [s]hifro[/s]
        [s]hump[/s]
        [s]hump2[/s]
        [s]jebus[/s]
        [s]leaf[/s]
        [s]loony[/s]
        [s]lockstock[/s]
        [s]pimp[/s]
        [s]pingu[/s]
        [s]spank[/s]
        [s]surprise[/s]
        [s]turban[/s]
*/
