import styled from '@emotion/styled';

export const ActionBar = styled.div`
    text-transform: uppercase;
    border: 1px solid ${(props) => props.theme.grey.main};
    border-radius: 4px;
    padding: ${(props) => props.theme.spacing(3)};
    background-color: ${(props) => props.theme.white};
`;

export const PaginationActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 26px;

    @media (min-width: 671px) {
        width: unset;
        height: unset;
    }
`;
