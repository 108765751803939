import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { withTransientProps } from '../../helpers/transientStylingProp';

export const AnonymousCheckbox = styled(Checkbox)`
    border-radius: ${(props) => props.theme.spacing(2)};
    color: ${(props) => props.theme.text.secondary};
`;

export const AnonymousFormLabel = styled(
    FormControlLabel,
    withTransientProps
)<{
    $location: string;
}>(
    ({ theme, $location }) => css`
        color: ${$location == 'new-aaq' ? theme.palette.primary.main : $location == 'aaq' ? theme.white : theme.text.secondary};
        margin-right: auto;

        & .MuiTypography-root {
            font-size: 14px;
            font-weight: normal;
        }
    `
);

export const AnonymousFormGroup = styled(FormGroup)`
    margin-right: auto;
`;
