import { useState, useCallback, useEffect } from 'react';
import useTranslation from '../../hooks/useTranslation';
import { WatchButtonContainer } from './styles';
import { recordClick } from '@thestudentroom/datalayer';
import { useMutation as useGraphQLMutation } from '@apollo/client';
import { WATCH_THREAD, UNWATCH_THREAD } from '../../api/Notification/graph';
import { GET_THREAD } from '../../api/Thread/graph';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../client';
import getSecurityToken from '../../helpers/getSecurityToken';
import { updateSecurityToken } from '../../store/user/actions';
import { showModal } from '../../store/modals/actions';
import { WatchButton as WatchButtonComponent } from '@thestudentroom/lib.themes';


interface WatchButtonProps {
    watchType: 'thread' | 'forum';
    threadId?: number;
    forumId?: number;
    forumCategoryId?: number;
    replyCount?: number;
    watched: boolean;
}

export default function WatchButton(props: WatchButtonProps) {
    const { t } = useTranslation();
    const userId: number = parseInt(useSelector((state: RootState) => state.user.userId));
    const [isWatched, setIsWatched] = useState(userId == 0 ? false : props.watched);
    const reduxDispatch = useDispatch();

    const [watchThread] = useGraphQLMutation(WATCH_THREAD, {
        update: (cache, mutationResult) => {
            if (mutationResult.data.watchThread.watched) {
                setIsWatched(true);
                const threadData: any = cache.readQuery({
                    query: GET_THREAD,
                    variables: { threadId: props.threadId, isMember: true }
                });
                // update thread query with new data
                threadData &&
                    cache.writeQuery({
                        query: GET_THREAD,
                        variables: { threadId: props.threadId, isMember: true },
                        data: {
                            thread: {
                                ...threadData.thread,
                                watched: true
                            }
                        }
                    });
            }
        }
    });

    const [unwatchThread] = useGraphQLMutation(UNWATCH_THREAD, {
        update: (cache, mutationResult) => {
            if (!mutationResult.data.unwatchThread.watched) {
                setIsWatched(false);
                const threadData: any = cache.readQuery({
                    query: GET_THREAD,
                    variables: { threadId: props.threadId, isMember: true }
                });
                // update thread query with new data
                threadData &&
                    cache.writeQuery({
                        query: GET_THREAD,
                        variables: { threadId: props.threadId, isMember: true },
                        data: {
                            thread: {
                                ...threadData.thread,
                                watched: false
                            }
                        }
                    });
            }
        }
    });

    function toggleWatch(isWatched: boolean){
        getSecurityToken().then((token: string) => {
            reduxDispatch(updateSecurityToken(token));
        });
        if (!isWatched) {
            watchThread({ variables: { threadId: props.threadId } });
        } else if (isWatched) {
            unwatchThread({ variables: { threadId: props.threadId } });
        }
    }

    const displayText = isWatched ? t('buttons.unwatch') : t('buttons.watch');

    const handleOnClick = useCallback(
        async (userId: number) => {
            recordClick({
                group: 'thread-watching',
                section: 'thread-header',
                subsection: 'top',
                item: displayText.toLowerCase() + ' this ' + props.watchType,
                action: isWatched ? 'unwatch' : 'watch',
                forumId: props.forumId,
                forumCategoryId: props.forumCategoryId,
                threadId: props.threadId,
                replyCount: props.replyCount
            });

            if (userId > 0) {
                // If user is a member, update watch status
               toggleWatch(isWatched)
            } else {
                // If user is a guest, open registration modal
                !isWatched && reduxDispatch(showModal(
                    true,
                    'watchthread',
                    {
                        route: 'watchthread',
                        afterRegister: (redirectUrl: string, userId: number) => {
                            // Putting this behind a timeout to ensure the UX is switched to the member experience fully
                            setTimeout(() => {
                                toggleWatch(isWatched)
                            }, 200);
                        },
                        afterLogin: (redirectUrl: string, userId: number) => {
                            setTimeout(() => {
                                toggleWatch(isWatched)
                            }, 200);
                        }
                    },
                    true
                ));
            }
        },
        [isWatched]
    );

    useEffect(() => {
        setIsWatched(userId == 0 ? false : props.watched);
    }, [userId, props.watched]);

    return (
        <WatchButtonContainer>
            <WatchButtonComponent
                $watched={isWatched}
                displayText={displayText}
                aria-label={displayText + ' this ' + props.watchType}
                onClick={() => handleOnClick(userId)}
            >
                <p>{displayText}</p>
            </WatchButtonComponent>
        </WatchButtonContainer>
    );
}
