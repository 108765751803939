import { ErrorMessageContainer } from './styles';
import { MdError } from 'react-icons/md';
import { useAppTheme } from '@thestudentroom/lib.themes';


interface ErrorMessageProps {
    errors: string[];
    location: string;
}

export default function ErrorMessage({ errors, location }: ErrorMessageProps): JSX.Element {
    const theme = useAppTheme();

    return (
        <>
            {errors.length > 0 &&
                errors.map((error, index) => {
                    return (
                        <ErrorMessageContainer
                            key={`error_${location}_${index}`}
                            style={{
                                marginTop: `${location == 'edit-post-title' ? theme.spacing(-2) : theme.spacing(2)}`,
                                marginBottom: `${location == 'edit-post-title' ? theme.spacing(2) : theme.spacing(0)}`,
                                width: `${location == 'create-aaq-thread-new' ?  '100%' : 'unset'}`
                            }}
                        >
                            <MdError
                                size={'19px'}
                                style={{
                                    marginRight: theme.spacing(2),
                                    marginTop: '-1px'
                                }}
                            />
                            {error}
                        </ErrorMessageContainer>
                    );
                })}
        </>
    );
}
