import { ReactNode } from 'react';

interface TableProps {
    children: ReactNode;
}

// created Table component in order to wrap table content in <tbody> tags
export default function Table(props: TableProps) {
    return (
        <table style={{ borderCollapse: 'collapse' }}>
            <tbody>{props.children}</tbody>
        </table>
    );
}
