import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

export const RelatedItemsWidgetContainer = styled.div<{ standalone: boolean }>`
    padding: ${(props) => (props.standalone ? props.theme.spacing(6) : props.theme.spacing(4, 0))};
    border: 1px ${(props) => props.theme.grey.main};
    border-style: ${(props) => (props.standalone ? 'solid' : 'solid none')};
    border-radius: ${(props) => props.theme.spacing(props.standalone ? 2 : 0)};
    background-color: ${(props) =>
        props.standalone ? props.theme.palette.background.paper : 'transparent'};
    margin-bottom: ${(props) => props.theme.spacing(props.standalone ? 5 : 0)};
`;

export const OuterRelatedItemsWidgetContainer = styled.div`
    width: 100%;
    padding: ${(props) => props.theme.spacing(0)};
    border: none;
`;

export const RelatedItemsWidgetHeader = styled.div`
    margin: ${(props) => props.theme.spacing(0, 0, 4, 0)};
    display: flex;
    gap: ${(props) => props.theme.spacing(1)};
    justify-content: space-between;
    align-items: center;
`;

export const RelatedItemsWidgetTitle = styled(Typography)<{ location?: string }>`
    margin: 0;
    color: ${(props) =>
        props.location === 'thread'
            ? props.theme.palette.primary.main
            : props.theme.palette.text.primary};
    ${(props) => props.theme.typography.h2};
    font-weight: 700;
    max-width: 60%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
