import { ReactNode } from 'react';
import { FieldContainer, FieldTitle } from './styles';

interface FieldProps {
    title: string;
    BBCodeParser: any;
    children: ReactNode;
}

export default function Field({ title, children, BBCodeParser }: FieldProps) {
    return (
        <FieldContainer component="fieldset">
            <FieldTitle>{title}</FieldTitle>
            <BBCodeParser content={children?.toString() ?? ''} isFirstPost={false} />
        </FieldContainer>
    );
}
