import { ChangeEvent } from 'react';
import useTranslation from '../../hooks/useTranslation';

// Custom styles
import { AnonymousCheckbox, AnonymousFormGroup, AnonymousFormLabel } from './styles';
import { useAppTheme } from '@thestudentroom/lib.themes';

interface AnonymousToggleProps {
    isAnonymous: boolean;
    toggleAnonymous: (event: ChangeEvent<any>) => void;
    location?: string;
}

export default function AnonymousToggle(props: AnonymousToggleProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();

    return (
        <AnonymousFormGroup data-testid="anon-toggle-group">
            <AnonymousFormLabel
                control={
                    <AnonymousCheckbox
                        aria-label={`${t('header.aaq.post-anonymously')} checkbox`}
                        sx={{
                            '&, &.Mui-checked': {
                                color: props.location == 'new-aaq' ? theme.palette.primary.main : props.location == 'aaq' ? theme.white : theme.text.secondary
                            }
                        }}
                    />
                }
                label={t('header.aaq.post-anonymously')}
                onChange={(event: ChangeEvent<any>) => {
                    props.toggleAnonymous(event);
                }}
                checked={props.isAnonymous}
                $location={props.location ?? ''}
            />
        </AnonymousFormGroup>
    );
}
