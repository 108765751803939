import styled from '@emotion/styled';
import useTranslation from '../../../hooks/useTranslation';

const Next = styled.p`
    text-transform: none;
    color: ${(props) => props.theme.palette.primary.main};
`;

export default function NextNavItem() {
    const { t } = useTranslation();
    return <Next>{t('pagination.next')}</Next>;
}
