import { useState } from 'react';
import styled from '@emotion/styled';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import theme from '../../Layouts/Main/theme';

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.text.secondary,
        ...theme.typography.caption,
        margin: `${theme.spacing(2, 0, 0, 0)} !important`,
        borderRadius: theme.spacing(2),
        padding: theme.spacing(3, 4)
    }
}));

export default function CustomTooltip(props: TooltipProps) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <DarkTooltip
            {...props}
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
            placement="bottom"
            leaveTouchDelay={2000} // increase the length of tooltip display on mobile
        >
            <Button
                sx={{
                    padding: theme.spacing(0),
                    justifyContent: 'center',
                    minWidth: 'unset',
                    cursor: 'default !important',
                    '&:hover': {
                        cursor: 'default !important'
                    }
                }}
                onClick={() => setShowTooltip(!showTooltip)}
            >
                {props.children}
            </Button>
        </DarkTooltip>
    );
}
