import styled from '@emotion/styled';

export const VolunteerTeamWidgetHeader = styled.h3`
    ${(props) => props.theme.typography.h2};
    margin: ${(props) => props.theme.spacing(0)};
    line-height: ${(props) => props.theme.spacing(5)};
`;

export const VolunteerTeamWidgetText = styled.p`
    color: ${(props) => props.theme.text.primary};
    line-height: ${(props) => props.theme.spacing(5)};
    margin: ${(props) => props.theme.spacing(5, 0, 0, 0)};
`;

export const VolunteerTeamMember = styled.p`
    color: ${(props) => props.theme.text.primary};
    display: inline;
    line-height: ${(props) => props.theme.spacing(5)};
`;

export const VolunteerTeamWidgetContainer = styled.div`
    background-color: ${(props) => props.theme.palette.primary.light};
    border-radius: ${(props) => props.theme.spacing(2)};
    padding: ${(props) => props.theme.spacing(5)};
`;

// These can be removed when the member pages are built in React
export const VolunteerTeamLink = styled.a`
    ${(props) => props.theme.typography.subtitle2};
    color: ${(props) => props.theme.text.primary};
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        text-decoration-color: ${(props) => props.theme.link.hover};
    }
    &:focus {
        text-decoration-color: ${(props) => props.theme.link.hover};
        border: 2px solid ${(props) => props.theme.link.focus};
        border-radius: ${(props) => props.theme.spacing(2)};
    }
`;
