import styled from '@emotion/styled';

export const ErrorContainer = styled.div`
    border: 1px solid #602120;
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    background-color: #fcedec;
    color: #d84643;
    font-weight: bold;
    padding: 0.5rem;
    height: fit-content;

    & > p {
        padding: 0.25rem 0.5rem;
        margin: 0.25rem;
        background-color: #f7d4d2;
        background-opacity: 0.5;
        font-weight: normal;
        color: #602120;
    }
`;
