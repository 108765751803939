import { Footnote, Subtitle, Title } from './styles';

interface SizeProps {
    size: string;
    children: React.ReactNode;
}

export default function Size({ size, children }: SizeProps) {
    switch (size) {
        case '1':
        case '2':
            return <Footnote>{children}</Footnote>;
        case '3':
            return <>{children}</>;
        case '4':
        case '5':
            return <Subtitle>{children}</Subtitle>;
        case '6':
        case '7':
            return <Title>{children}</Title>;
        default:
            return <>{children}</>;
    }
}
