import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { withTransientProps } from '../../../helpers/transientStylingProp';

export const QuoteContainer = styled.div<{ isThreadOpen: boolean }>`
    display: flex;
    padding: ${(props) => props.theme.spacing(5)};
    border-radius: 8px;
    background-color: ${(props) => props.isThreadOpen ? props.theme.palette.primary.light : props.theme.grey.light};
    position: relative;
`;

export const QuoteContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const OriginalPostInfo = styled.span`
    padding-bottom: ${(props) => props.theme.spacing(2)};
    font-weight: bold;
    line-height: 17px;
`;

export const ExpandButton = styled(Button)`
    ${(props) => props.theme.typography.button}
    padding: ${(props) => props.theme.spacing(0)};
    color: ${(props) => props.theme.text.secondary};
    white-space: nowrap;
    line-height: ${(props) => props.theme.spacing(4)} !important;
    margin-left: ${(props) => props.theme.spacing(2)};
    text-transform: none;
`;

export const ExpandButtonContainer = styled.div<{ isExpanded: boolean; lines: number }>`
    display: inline-block;

    & .MuiCollapse-root {
        width: 95%;
    }

    & .ImgFirst {
        position: relative;
    }

    & .ClipQuote .MuiCollapse-wrapperInner {
        display: -webkit-box;
        -webkit-line-clamp: ${(props) => (props.isExpanded ? 'unset' : props.lines)};
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    & .MuiCollapse-root .MuiCollapse-wrapperInner {
        word-break: break-word;
    }

    & .ImgFirst::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: ${(props) => props.theme.spacing(0)};
        top: ${(props) => props.theme.spacing(0)};
        background: linear-gradient(
            transparent ${(props) => props.theme.spacing(0)},
            ${(props) => props.theme.palette.primary.light}
        );
    }

    & .MuiButton-root {
        display: inline;
        padding: ${(props) => props.theme.spacing(props.isExpanded ? 1 : 2, 0)};
        margin-left: ${(props) => props.theme.spacing(0)};
    }

    @media (min-width: 671px) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        & .MuiButton-root {
            padding: ${(props) =>
                props.isExpanded ? props.theme.spacing(2, 0) : props.theme.spacing(1, 0)};
        }
    }
`;

export const DismissButton = styled(
    IconButton,
    withTransientProps
)<{
    $isExpandBlock: boolean;
}>(
    ({ theme, $isExpandBlock }) => css`
        align-self: flex-start;
        padding: 0px;
        margin-left: ${theme.spacing($isExpandBlock ? 1 : -3)};
        margin-top: ${theme.spacing($isExpandBlock ? 2 : -2)};
    }
`
);
