import { Fragment, ReactNode } from 'react';
import { ListContainer } from './styles';
import ListItem from './ListItem';

interface ListProps {
    listType: string;
    BBCodeParser: any;
    children: ReactNode;
}

export default function List({ listType, BBCodeParser, children }: ListProps) {
    const bulletPoints = children
        ?.toString()
        .split('[*]')
        .filter((item) => item.trim() !== '');

    return bulletPoints && bulletPoints.length > 0 ? (
        <ListContainer>
            {bulletPoints.map((item, index) => {
                return (
                    <ListItem key={`list-item-${index}`} listType={listType} index={index}>
                        {item.split('\n').map((line, lineIndex) => (
                            <Fragment key={`line-${lineIndex}`}>
                                <BBCodeParser content={line} isFirstPost={false} />
                                {lineIndex < item.split('\n').length - 1 && <br />}
                            </Fragment>
                        ))}
                    </ListItem>
                );
            })}
        </ListContainer>
    ) : null;
}
