const tags: string[] = [
    'br',
    'b',
    'i',
    'u',
    'img',
    'left',
    'right',
    'center',
    'font',
    'size',
    'color',
    'colour',
    'url',
    'user',
    'h2',
    'h3',
    'h4',
    'link',
    'strike',
    'sub',
    'sup',
    'hr',
    'lb',
    'div',
    'div2',
    'quote',
    'table',
    'tr',
    'th',
    'td',
    'thread',
    'forum',
    'expand',
    'spoiler',
    'smilie',
    's',
    'vimeo',
    'youtube',
    'attach',
    'email',
    'latex',
    'tex',
    'list',
    'code',
    'html',
    'php',
    'field',
    'noparse'
];

// TODO - Support / Deprecate below tags (source https://www.thestudentroom.co.uk/misc.php?do=bbcode)
// post
// alertgame
// countdown
// eventbrite
// ftvideo
// nulpoints
// opendaymap
// pre
// scroll
// scrollr
// surveymonkey
// tuitionfeemap
// video-upload
// vizaar
// wiki
// font
// highlight
// post
// a

// Support uppercase versions of the tags as well
export const supportedTags = tags.concat(tags.map((x) => x.toUpperCase()));
