import loadable from '@loadable/component';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { GET_TSR_POLL } from '../../api/Poll/graph';
import LoadingPoll from './LoadingPoll';
import LazyLoad from '../LazyLoad';
import { PollContainer } from './styles';
import styled from '@emotion/styled';

const Poll = loadable(() => import('./index'));

const Desktop = styled.div`
    display: block;

    @media (max-width: 980px) {
        display: none;
    }
`;

interface TSRPollProps {
    threadId?: number;
    forumId?: number;
    forumCategoryId?: number;
}

export default function TSRPoll(props: TSRPollProps) {
    const tsrPollData = useGraphQLQuery(GET_TSR_POLL, {
        errorPolicy: 'all',
        ssr: true
    });

    const tsrPoll = tsrPollData.data && tsrPollData.data.tsrPoll ? tsrPollData.data.tsrPoll : null;

    if (tsrPollData.error) return null;
    if (!tsrPoll) return null;

    return tsrPollData && tsrPollData.loading ? (
        <Desktop>
            <LoadingPoll />
        </Desktop>
    ) : (
        <Desktop>
            <PollContainer data-cy="poll" $isThreadPoll={false}>
                <LazyLoad
                    key={'lazyload-poll-widget'}
                    offset={300}
                    placeholder={<LoadingPoll />}
                >
                    <Poll
                        pollId={tsrPoll.id}
                        question={tsrPoll.question}
                        options={tsrPoll.options}
                        open={tsrPoll.open}
                        threadId={tsrPoll.thread_id}
                        isThreadPoll={false}
                        showResults={tsrPoll.show_results}
                        voted={tsrPoll.voted}
                        canVote={tsrPoll.can_vote}
                        multiple={tsrPoll.multiple}
                        refetch={tsrPollData.refetch}
                        numberOfVotes={tsrPoll.num_of_votes}
                        currentThreadId={props.threadId}
                        forumId={props.forumId}
                        forumCategoryId={props.forumCategoryId}
                    />
                </LazyLoad>
            </PollContainer>
        </Desktop>
    );
}
