export function getRegPromptTitle(regRoute: string) {
    switch (regRoute) {
        case 'returnvisitregprompt':
        case 'threadregprompt':
        case 'headersignup':
        case 'home_signup':
        case 'homefeedsignup':
            return {
                register: 'Sign up to get personalised advice and boost your academic success',
                login: 'Log in to get personalised advice and boost your academic success'
            };
        case 'poll':
            return {
                register: 'Your vote matters. Sign up to include your vote',
                login: 'Your vote matters. Log in to include your vote'
            };
        case 'guest_askaquestion':
            return {
                register: 'Begin a discussion by signing up',
                login: 'Begin a discussion by logging in'
            };
        case 'report_post':
            return {
                register: 'Thank you for keeping our community safe. Sign up to report issues',
                login: 'Thank you for keeping our community safe. Log in to report issues'
            };
        case 'watchthread':
            return {
                register: 'Stay updated. Sign up for updates to this discussion',
                login: 'Stay updated. Log in for updates to this discussion'
            };
        case 'slc':
            return {
                register: 'Sign up to be the first to know when full-time undergraduate student finance applications go live',
                login: 'Log in to be the first to know when full-time undergraduate student finance applications go live'
            };
        case 'helpfulanswers':
            return {
                register: 'You are one small step away. Sign up to give someone rep',
                login: 'You are one small step away. Log in to give someone rep'
            };
        case 'postreply':
            return {
                register: 'Your response matters. Sign up to share it',
                login: 'Your response matters. Log in to share it'
            };
        case 'ownership-block':
            return {
                register: 'Sign up to request information about this university',
                login: 'Log in to request information about this university'
            };
        default:
            return {
                register: 'Sign up to get personalised advice and boost your academic success',
                login: 'Log in to get personalised advice and boost your academic success'
            };
    }
}
