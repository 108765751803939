import styled from '@emotion/styled';

export const ErrorMessageContainer = styled.div`
    display: flex;
    align-items: center;
    ${(props) => props.theme.typography.caption};
    color: ${(props) => props.theme.text.primary};
    background-color: ${(props) => props.theme.white};
    border-radius: 5px;
    margin-top: 5px;
    padding: ${(props) => props.theme.spacing(2, 3)};
    font-weight: bold;
    border: ${(props) => `2px solid ${props.theme.palette.info.main}`};
`;
