import Skeleton from '@mui/material/Skeleton';
import { PollContainer } from './styles';
import styled from '@emotion/styled';

const PollButtonSkeleton = styled(Skeleton)`
    width: 100%;
    height: 33px;
    margin-top: 5px;
    border-radius: 60px;
`;

export default function LoadingPoll() {
    return (
        <PollContainer $isThreadPoll={true}>
            <Skeleton variant="text" width={"15%"} height={20} />

            <Skeleton variant="text" width={"100%"} height={40} />
            <Skeleton variant="text" width={"100%"} height={40} />
            <Skeleton variant="text" width={"30%"} height={40} />

            <PollButtonSkeleton variant="rectangular" />
            <PollButtonSkeleton variant="rectangular" />
            <PollButtonSkeleton variant="rectangular" />
        </PollContainer>
    );
}
